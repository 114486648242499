import React, { useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index';

function Protected() {
  const navigate = useNavigate();
  const location = useLocation()
  // console.log('location = ',location)
    useEffect(()=>{
      let token = localStorage.getItem('Login')
      if(!token){
        navigate(`/?path=${location?.pathname.replace("/","")}`)
      }
    },[]);
      
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
      <div className="body flex-grow-1 px-3">  
        <Outlet />
      </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default Protected
