import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import LayersIcon from '@mui/icons-material/Layers';
import RuleIcon from '@mui/icons-material/Rule';
import AddCardIcon from '@mui/icons-material/AddCard';
import FlakyIcon from '@mui/icons-material/Flaky';
import ExtensionIcon from '@mui/icons-material/Extension';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CategoryIcon from '@mui/icons-material/Category';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactPageIcon from '@mui/icons-material/ContactPage';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import user_lst from './assets/images/ion_list-outline.svg';

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <span style={{marginRight:'5px'}}><DashboardIcon /></span>,
  },
  {
    component: CNavGroup,
    name: 'User Module',
    to: '/',
    icon: <span style={{marginRight:'5px'}}><GroupIcon /></span>,
    items: [
      {
        component: CNavItem,
        name: 'Users List',
        to: '/users',
        icon: <span style={{marginRight:'5px'}}><FormatListBulletedIcon /></span>
      }
    ],
  },
  {
    component: CNavGroup,
    name: 'Contact Module',
    to: '/',
    icon: <span style={{marginRight:'5px'}}><EmojiEventsIcon /></span>,
    items: [
      {
        component: CNavItem,
        name: 'Contact List',
        to: '/contact',
        icon: <span style={{marginRight:'5px'}}><FormatListBulletedIcon /></span>,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Appointment Module',
    to: '/',
    icon: <span style={{ marginRight: '5px' }}><AssessmentIcon /></span>,
    items: [
      {
        component: CNavItem,
        name: 'Appointment List',
        to: '/appoitment',
        icon: <span style={{marginRight:'5px'}}><FormatListBulletedIcon /></span>
      }
    ],
  },
  {
    component: CNavGroup,
    name: 'Version Module',
    to: '/',
    icon: <span style={{marginRight:'5px'}}><AcUnitIcon /></span>,
    items: [
      {
        component: CNavItem,
        name: 'Version update',
        to: '/version-update',
        icon: <span style={{marginRight:'5px'}}><FormatListBulletedIcon /></span>
      },
    ],
  },
 
]

export default _nav
