// import counterReducer from './screens/Counter/CounterSlice'
// import { configureStore } from '@reduxjs/toolkit'
// import { combineReducers } from 'redux'
// import { persistStore, persistReducer,FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER, } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

// const rootReducer = combineReducers({
//   counter: counterReducer
// })

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
 
// const  store =configureStore({
//     reducer:persistedReducer,
//     devTools: process.env.NODE_ENV !== 'production',
//     middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//         serializableCheck: {
//             // need to add unnecessary action in a list for avoiding
//             // errors and warning
//         ignoredActions: [
//           PERSIST,
//           FLUSH, 
//           REHYDRATE, 
//           PAUSE, 
//           PURGE,
//           REGISTER
//           ],
//         },
//     }),
//   })
    

// export const persistor = persistStore(store)

// export default store;


import { legacy_createStore as createStore } from 'redux'

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store

