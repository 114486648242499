import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Pages from './Routes/route'
import './scss/style.scss'
import './scss/athes.css'
import './assets/global.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <BrowserRouter>
      <Pages/>
      <ToastContainer/>
    </BrowserRouter>
    </>
  );
}

export default App;
