import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MyTextInput } from '../FormikInputField'
import * as Yup from 'yup'
import './Login.css'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { LoginApi } from 'src/services/Apis'
import { getLocalStorageToken, setLocalStorageToken } from 'src/utils/common'

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const [loginFlag, setLoginFlag] = useState(true)

  var CheckLogin

  const handleEmail = (event, props) => {
    const lowercaseEmail = event.target.value.toLowerCase()
    props.setFieldValue('email', lowercaseEmail)
  }

  const handleVisible = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    CheckLogin = localStorage.getItem('Login')
    if (CheckLogin) {
      navigate('/dashboard')
    }
    // setLoginFlag(true)
  }, [])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .email('Invalid Email')
                        // .max(40, 'Maximum limit is 40 characters')
                        .test('lowercase', 'Capital letters are not allowed', function (value) {
                          if (/[A-Z]/.test(value)) {
                            return false // Fail validation if uppercase letters are found
                          }
                          return true // Pass validation if no uppercase letters are found
                        })
                        .required('Please enter Email'),
                      password: Yup.string().required('Please enter password'),
                      // .min(8, 'Password must be at least 8 characters long')
                      // .matches(
                      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+{}[\]|;:'",.<>/?`~\\]+$/,
                      //   'Password must contain at least one uppercase letter, one lowercase letter, and one digit'
                      // )
                    })}
                    onSubmit={async (values, { setSubmitting, setFieldError }) => {
                      try {
                        setLoginFlag(false)
                        setSubmitting(true)
                        var data = {
                          email: values.email,
                          password: values.password,
                        }
                        var res = await LoginApi(data)
                        console.log('login res = ', res)
                        if (res.data.token) {
                          // localStorage.setItem('LoginName', res.data.data?.name);
                          // localStorage.getItem('LoginName', res.data.data?.name);
                          setLocalStorageToken('LoginName', res.data?.user_name)
                          setLocalStorageToken('Login', res.data?.token)
                          CheckLogin = getLocalStorageToken('Login')
                          if (CheckLogin) {
                            setLoginFlag(true)
                            if (location?.search) {
                              let data = Object.fromEntries(new URLSearchParams(location?.search))
                              navigate(`/${data?.path}`)
                            } else {
                              navigate('/dashboard')
                            }
                            toast.success('Logged in Successfully.')
                          }
                        } else {
                          setLoginFlag(true)
                          setFieldError('password', 'Invalid email or password')
                        }
                      } catch (error) {
                        setLoginFlag(true)
                        toast.error(error?.response?.data?.message)
                        console.log({ Message: 'Data Not Found.', Error: error })
                      } finally {
                        setSubmitting(false)
                      }
                    }}
                  >
                    {(props) => {
                      return (
                        <Form className="login-form">
                          <h1>Login</h1>
                          <p className="text-medium-emphasis">Sign In to your account</p>

                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilUser} />
                            </CInputGroupText>
                            <MyTextInput
                              type="email"
                              className="form-control round-corner"
                              name="email"
                              placeholder="Enter Email"
                              // onChange={(e)=>handleEmail(e,props)}
                            />
                          </CInputGroup>

                          <CInputGroup className="mb-4">
                            <CInputGroupText>
                              <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <MyTextInput
                              type={visible ? 'text' : 'password'}
                              className="form-control col-md-10"
                              name="password"
                              placeholder="Enter Password"
                            />
                            <CInputGroupText>
                              {visible ? (
                                <VisibilityIcon onClick={handleVisible} />
                              ) : (
                                <VisibilityOffIcon onClick={handleVisible} />
                              )}
                            </CInputGroupText>
                          </CInputGroup>

                          <CRow>
                            <CCol xs={6}>
                              <input
                                type="submit"
                                disabled={!loginFlag}
                                value="Login"
                                className="btn btn-btn btn-secondary text-white border-0 px-4"
                              />
                            </CCol>
                          </CRow>
                        </Form>
                      )
                    }}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
