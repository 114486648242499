import React, { useState, useEffect} from 'react'
import { BrowserRouter, Route, Outlet, Routes, useNavigate } from 'react-router-dom'
import '../scss/style.scss'
import Protected from './Protected'
import * as Screens from '../screens'; 
import { Suspense } from 'react';
import Loader from 'src/screens/Loader/Loader';

function Pages() {
    return (
        <>
        <Suspense fallback={<><div><Loader /></div></>}>
            <Routes>
                <Route exact path="/" name="Login Page" element={<Screens.Login />} />
                <Route exact path="/delete-user" name="App Delete" element={<Screens.AppDelete />} />
                <Route exact path="/thankyou" name="Thank You" element={<Screens.ThankYou />} />
                <Route exact path="/forgot" name="Forgot" element={<Screens.Forgot />} />
                <Route exact path="/reset-password/:id" name="Reset Password" element={<Screens.ResetPassword />} />
                    <Route element={<Protected />}>
                        <Route exact path="/dashboard" name="Dashboard" element={<Screens.Dashboard />} />
                        <Route exact path="/users" name="Users" element={<Screens.Users />} />
                        <Route exact path="/userdetails/:id" name="User Details" element={<Screens.UserDetails />} />
                        <Route exact path="/appoitment" name="Appoitment" element={<Screens.Appoitment />} />
                        <Route exact path="/contact" name="Contact" element={<Screens.Contact />} />
                        
                        <Route exact path="/version-update" name="Version Update" element={<Screens.IOS/>} />
                        <Route exact path="/transaction" name="Transaction" element={<Screens.Transaction />} />
                        {/* <Route exact path="/transaction-details/:id" name="Transaction Details" element={<Screens.TransactionDetails/>} /> */}
                    </Route>
                {/* <Route exact path="*" name="Page 404" element={<Screens.Page404 />} /> */}
                {/* <Route exact path="/500" name="Page 500" element={<Screens.Page500 />} /> */}
                {/* <Route path="*" name="Home" element={<Screens.DefaultLayout />} /> */}
            </Routes>
        </Suspense>  
        {/* <Routes>
            <Route exact path="/privacy-policy" name="Privacy Policy" element={<Screens.PrivacyPolicy/>} />
            <Route exact path="/pages/:id" name="About Us" element={<Screens.AboutUs/>} />
        </Routes> */}
        </>    
    );
}

export default Pages
