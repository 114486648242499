import React from 'react'
import { Link } from 'react-router-dom'

function ThankYou() {
  return (
    <>
      <div className='d-flex align-items-center justify-content-center vh-100 thankYou-bg'>
          <div className='text-center'>
            <img src='/manaLogo.svg' alt="logo" className="mx-auto d-flex py-2 my-1" width="150"/>
            <h1 className='fw-bold mt-3'>Thank You</h1>
            <p className='my-4'>User will be deleted after Admin's action.</p>
            <Link to={"/"} 
              className='custom-primary py-2 rounded px-4 text-decoration-none d-inline-block'
            >
            Go to Home
            </Link>
          </div>
      </div>
    </>
  )
}

export default ThankYou
