import React, { useState, useEffect } from 'react'
import ToggleSwitch from './ToggleSwitch'
import Switch from '@mui/material/Switch'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { VersionGetApi, VersionUpdateApi, VersionNotificationApi } from 'src/services/Apis'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Loader from '../Loader/Loader'
import CommonAlert from '../../utils/Alert/CommonAlert.js'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

export default function Ios() {
  const [androidVersion, setAndroidVersion] = useState('')
  const [iosVersion, setIosVersion] = useState('')
  const [displayIos, setDisplayIos] = useState('')
  const [displayAndroid, setDisplayAndroid] = useState('')

  const [VersionId, setVersionId] = useState()
  const [toggle, setToggle] = useState(false)
  const [Forced, setForced] = useState(false)
  const [flag, setFlag] = useState(false)
  const [saveFlag, setSaveFlag] = useState(false)
  const [notyFlag, setNotyFlag] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [loaderDataFlag, setLoaderDataFlag] = useState(true)
  const [loaderFlag, setLoaderFlag] = useState(false)
  const [forcedUpdate, setForcedUpdate] = useState(false)
  const [open, setOpen] = useState(false)

  const loadUser = async () => {
    setLoaderFlag(true)
    try {
      const res = await VersionGetApi()

      // Find the Android version data
      const data = res.data?.[0]
      console.log('Version data res = ', data)
      setAndroidVersion(data?.android_version)
      setIosVersion(data?.ios_version)
      setToggle(data?.force_update)
      setForcedUpdate(data?.force_update)
      setDisplayAndroid(data?.android_version)
      setDisplayIos(data?.ios_version)
      setVersionId(data?._id)
      setLoaderFlag(false)
    } catch (error) {
      setLoaderFlag(false)
      console.log({ Message: 'Api Not Working', Error: error })
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  // const handleNotify = async () => {
  //   setNotyFlag(false)
  //   var data = {
  //     os: "ios"
  //   }
  //   // console.log('data = ',data)
  //   await VersionNotificationApi(data).then((result) => {
  //     if (result.data.status) {
  //       toast.success('Notification sent successfully')
  //     }
  //   }).catch((error) => {
  //     console.log({ Error: error })
  //   })
  // }

  const handelVer = (e, version, props) => {
    var val = e.target.value
    let regex = /^\d+\.\d+\.\d+$/
    let checkRegex = regex.test(val)

    if (version == 'ios') {
      setIosVersion(val)
      if (checkRegex) {
        props.setFieldValue('latestIosVersion', val)
        console.log(true)
        // props.setFieldTouched('latestIosVersion', false)
      } else {
        console.log(false, props)
        props.setFieldValue('latestIosVersion', '')
        props.setFieldTouched('latestIosVersion', true)
        props.setFieldError(
          'latestIosVersion',
          'Please enter a valid version number (X.Y.Z format)',
        )
      }
    } else {
      setAndroidVersion(val)
      if (checkRegex) {
        props.setFieldValue('latestAndroidVersion', val)
        // props.setFieldTouched('latestAndroidVersion', false)
      } else {
        props.setFieldValue('latestAndroidVersion', '')
        props.setFieldTouched('latestAndroidVersion', true)
        props.setFieldError(
          'latestAndroidVersion',
          'Please enter a valid version number (X.Y.Z format)',
        )
      }
    }

    // let chk = compareVersions(val,oldVersion)
    console.log('checkRegex = ', checkRegex)
    console.log('val = ', val)
    console.log('version = ', version)
  }
 
  function compareVersions(newVersion, oldVersion) {
    // Assuming versions are in the format "X.Y.Z"
    const newVersionParts = newVersion.split('.').map(Number)
    const oldVersionParts = oldVersion.split('.').map(Number)

    for (let i = 0; i < Math.max(newVersionParts.length, oldVersionParts.length); i++) {
      const newValue = newVersionParts[i] || 0
      const oldValue = oldVersionParts[i] || 0
      if (newValue > oldValue) {
        return 1
        // console.log('newValue = ',1)
      } else if (newValue < oldValue) {
        return -1
        // console.log('oldValue = ',-1)
      }
    }
    // console.log('last = ',0)
    return 0 // Versions are equal
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const handleEnable = () => {
  //   setOpen(false)
  //   setLoaderFlag(true)
  //   setLoaderDataFlag(true)
  //   setNotyFlag(true)
  //   var data = {
  //     id: VersionId,
  //     latestVersion: NewVersionUpdate,
  //     forced: toggle,
  //   }
  //   VersionUpdateApi(data)
  //     .then((res) => {
  //       setLoaderDataFlag(false)
  //       setLoaderFlag(false)
  //       toast.success('Version degrade successfully')
  //       loadUser()
  //     })
  //     .catch((error) => {
  //       setLoaderDataFlag(false)
  //       setLoaderFlag(false)
  //       console.log({ Message: 'Api not working', Error: error })
  //     })
  // }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          latestIosVersion: iosVersion,
          latestAndroidVersion: androidVersion,
          // forced: toggle,
        }}
        validationSchema={Yup.object({
          latestIosVersion: Yup.string()
            .required('Please enter ios version')
            .matches(/^\d+\.\d+\.\d+$/, 'Please enter a valid version number (X.Y.Z format)'),
          latestAndroidVersion: Yup.string()
            .required('Please enter android version')
            .matches(/^\d+\.\d+\.\d+$/, 'Please enter a valid version number (X.Y.Z format)'),
        })}
        onSubmit={(values) => {
          setLoaderFlag(true)
          setLoaderDataFlag(true)
          setNotyFlag(true)
          var data = {
            ios_version: values.latestIosVersion,
            android_version: values.latestAndroidVersion,
            force_update: toggle,
          }
          // console.log('data = ',data)
          VersionUpdateApi(data)
            .then((res) => {
              setLoaderDataFlag(false)
              setLoaderFlag(false)
              toast.success('Version upgrade successfully')
              loadUser()
            })
            .catch((error) => {
              setLoaderDataFlag(false)
              setLoaderFlag(false)
              console.log({ Message: 'Api not working', Error: error })
            })
        }}
      >
        {(props) => {
          return (
            <Form encType="multipart/form-data">
              <div className="card p-3 content-main ">
                <div
                  className="card-header fw-bold"
                  style={{ 'border-radius': '7px 7px 0px 0px', padding: '10px', height: '40px' }}
                >
                  Version Update
                </div>
                <br />
                <div className="row version-row">
                  <div className="col-5">
                    <label>Android Version:</label>
                    <input
                      type="text"
                      name="latestAndroidVersion"
                      value={androidVersion !== '' ? androidVersion : ''}
                      onChange={(e) => handelVer(e, 'android', props)}
                      className="form-control"
                    />
                    {props.touched?.latestAndroidVersion && props.errors?.latestAndroidVersion && (
                      <small style={{ color: 'red', fontSize: '12px' }} className="errores">
                        {props.errors?.latestAndroidVersion}
                      </small>
                    )}
                  </div>
                  <div className="col-5">
                    <label>Ios Version:</label>
                    <input
                      type="text"
                      name="latestIosVersion"
                      value={iosVersion !== '' ? iosVersion : ''}
                      onChange={(e) => handelVer(e, 'ios', props)}
                      className="form-control"
                    />
                    {props.touched?.latestIosVersion && props.errors?.latestIosVersion && (
                      <small style={{ color: 'red', fontSize: '12px' }} className="errores">
                        {props.errors?.latestIosVersion}
                      </small>
                    )}
                  </div>

                  <div className="col-2">
                    <label className="switch">Forced Update </label>
                    <p>
                      <Switch onChange={() => setToggle(!toggle)} className="form-control" />
                    </p>
                  </div>
                </div>

                <div className="text-start">
                  <Button type="submit" variant="btn btn-secondary mt-4">
                    {' '}
                    Update{' '}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      {loaderFlag && loaderDataFlag ? <Loader /> : ''}
      <CommonAlert
        open={open}
        message="Are you sure you want to degrade the version?"
        handleClose={handleClose}
        // handleEnable={handleEnable}
      />

      {/* <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{'color':'black'}}>
            Are you sure you want to degrade the version?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>No</Button>
          <Button onClick={handleEnable} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog> */}

      {/* <div className="container" style={{ width: '36%' }}>
        <br />
        <div className="output">
          <h3>
            <u>Display Panel</u>
          </h3>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Android Version</th>
                <th scope="col">Ios Version</th>
                <th scope="col">Forced Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{displayAndroid}</td>
                <td>{displayIos}</td>
                <td>
                  {forcedUpdate ? (
                    <DoneIcon style={{ color: 'green' }} />
                  ) : (
                    <CloseIcon style={{ color: 'red' }} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br /> */}
        {/* <button className="btn btn-primary" onClick={handleNotify} disabled={!notyFlag}>
          Notify All
        </button> */}
      {/* </div> */}


      <div className='card p-3 mt-4'>
          <div className="card-header fw-bold mb-4" style={{ 'border-radius': '7px 7px 0px 0px', padding: '10px', height: '40px' }}
          >
            Display Panel
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Android Version</th>
                <th scope="col">Ios Version</th>
                <th scope="col">Forced Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{displayAndroid}</td>
                <td>{displayIos}</td>
                <td>
                  {forcedUpdate ? (
                    <DoneIcon style={{ color: 'green' }} />
                  ) : (
                    <CloseIcon style={{ color: 'red' }} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </>
  )
}
