import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MyTextInput } from '../FormikInputField'
import * as Yup from 'yup'
import './AppDelete.css'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { DeleteAppStoreApi, VerifyOTPAppStoreApi } from 'src/services/Apis'
import { getLocalStorageToken, setLocalStorageToken } from 'src/utils/common'

const Login = () => {
  const navigate = useNavigate()
  const [emailFlag, setEmailFlag] = useState(true)
  const [email, setEmail] = useState()

  var CheckLogin

  const handleEmail = (event, props) => {
    const lowercaseEmail = event.target.value.toLowerCase()
    setEmail(lowercaseEmail)
    props.setFieldValue('email', lowercaseEmail)
  }

  useEffect(() => {
    setEmailFlag(true)
  }, [])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {emailFlag ? (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: '',
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email('Invalid Email')
                          // .max(40, 'Maximum limit is 40 characters')
                          .test('lowercase', 'Capital letters are not allowed', function (value) {
                            if (/[A-Z]/.test(value)) {
                              return false // Fail validation if uppercase letters are found
                            }
                            return true // Pass validation if no uppercase letters are found
                          })
                          .required('Please enter Email'),
                      })}
                      onSubmit={(values) => {
                        var data = {
                          email: values.email,
                        }
                        console.log('data = ', data)
                        DeleteAppStoreApi(data)
                          .then((res) => {
                            toast.success('OTP send successfully')
                            setEmailFlag(false)
                          })
                          .catch((error) => {
                            console.log({ Message: 'Api not working', Error: error })
                          })
                      }}
                    >
                      {(props) => {
                        return (
                          <Form className="login-form">
                            <h1>Delete User</h1>
                            <p className="text-medium-emphasis">Email</p>

                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilUser} />
                              </CInputGroupText>
                              <MyTextInput
                                type="email"
                                className="form-control round-corner"
                                name="email"
                                placeholder="Enter Email"
                                onChange={(e) => handleEmail(e, props)}
                              />
                            </CInputGroup>

                            <CRow>
                              <CCol xs={6}>
                                <input
                                  type="submit"
                                  value="Submit"
                                  className="btn btn-btn btn-secondary text-white border-0 px-4"
                                />
                              </CCol>
                            </CRow>
                          </Form>
                        )
                      }}
                    </Formik>
                  ) : (
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        otp: '',
                      }}
                      validationSchema={Yup.object({
                        otp: Yup.string().required('Please enter otp'),
                      })}
                      onSubmit={(values) => {
                        var data = {
                          email: email,
                          otp: values.otp,
                        }
                        console.log('data = ', data)
                        VerifyOTPAppStoreApi(data)
                          .then((res) => {
                            console.log('res = ', res)
                            toast.success('OTP verify successfully')
                            navigate('/thankyou')
                          })
                          .catch((error) => {
                            console.log({ Message: 'Api not working', Error: error })
                          })
                      }}
                    >
                      {(props) => {
                        return (
                          <Form className="login-form">
                            <h1>Verification OTP</h1>
                            <p className="text-medium-emphasis">OTP</p>

                            <CInputGroup className="mb-4">
                              <CInputGroupText>
                                <CIcon icon={cilUser} />
                              </CInputGroupText>
                              <MyTextInput
                                type="text"
                                className="form-control round-corner"
                                name="otp"
                                placeholder="Enter OTP"
                                // onChange={(e) => handleEmail(e, props)}
                              />
                            </CInputGroup>

                            <CRow>
                              <CCol xs={6}>
                                <input
                                  type="submit"
                                  value="Verify"
                                  className="btn btn-btn btn-secondary text-white border-0 px-4"
                                />
                              </CCol>
                            </CRow>
                          </Form>
                        )
                      }}
                    </Formik>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
