import React from "react"

export const Dashboard = React.lazy(()=> import('./dashboard'));
export const Users = React.lazy(() => import('./Users'));
export const UserDetails = React.lazy(() => import('./Users/UserDetails'));
export const Appoitment = React.lazy(() => import('./Appoitment'));
export const Contact = React.lazy(() => import('./Contact'))
export const Forgot = React.lazy(() => import('./Forgot/Forgot'))
export const ResetPassword = React.lazy(() => import('./Forgot/ResetPassword'))

export const Transaction = React.lazy(() => import('./Transaction'));
// export const TransactionDetails = React.lazy(() => import('./Transaction/TransactionDetails'));

// export const AboutUs = React.lazy(()=> import('./AboutUs'));

// export const PrivacyPolicy = React.lazy(()=> import('./AboutUs/PrivacyPolicy'));

export { Login } from './login';
export { AppDelete, ThankYou } from './AppStoreDelete'
export {default as AboutUs} from "./AboutUs"
export {default as PrivacyPolicy} from './AboutUs/PrivacyPolicy'
export {IOS} from "./Versions"

