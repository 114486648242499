import React, { useState } from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import CIcon from '@coreui/icons-react'
import AdminUser from '../../assets/images/levelUpBonus/AdminUser.png'
// import avatar8 from './../../assets/images/avatars/8.jpg'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import LockResetIcon from '@mui/icons-material/LockReset'
// import Modals from '../../screens/ChangePaswordModals/ChangePwdModals'
// import ChangePwdModal from './ChangePwdModal'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { getLocalStorageToken } from 'src/utils/common'
import CommonAlert from '../../utils/Alert/CommonAlert.js'
import CommonModal from 'src/utils/Modal/CommonModal'
import { MyTextInput } from 'src/screens/FormikInputField'
import { ChangePasswordApi } from 'src/services/Apis'
import { NotificationModal } from './Notification'

const AppHeaderDropdown = () => {
  const navigate = useNavigate()
  const [changePd, setChangePd] = useState(false)
  const [savePassword, setSavePassword] = useState(true)
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleCfmPwd, setVisibleCfmPwd] = useState(false)
  const [openNotify, setOpenNotify] = useState(false)

  const logoutUser = () => {
    // console.log('logout');
    setOpen(false)
    localStorage.removeItem('Login')
    navigate('/')
    toast.success('Logged out Successfully.')
  }

  const handleClose = () => setChangePd(false)
  const handleShow = () => setChangePd(true)
  const handleVisible = () => {
    setVisible(!visible)
  }
  const handleVisibleCfmPWD = () => {
    setVisibleCfmPwd(!visibleCfmPwd)
  }

  function capitalizeWords(sentence) {
    let nm = 'admin'
    var words = sentence ? sentence.toLowerCase()?.split(' ') : nm.toLowerCase()?.split(' ')
    for (var i = 0; i < words?.length; i++) {
      words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1)
    }
    return words?.join(' ')
  }
  // getLocalStorageToken("LoginName")
  const LoginName = capitalizeWords(getLocalStorageToken('LoginName'))

  const handleConfirmClose = () => {
    setOpen(false)
  }

  const handleConfirmOpen = () => {
    setOpen(true)
  }

  const handleNotification = () => { 
    setOpenNotify(!openNotify);
  }

  return (
    <>
      <div className="me-3 pt-2">
        <img src="/images/notification.svg" onClick={handleNotification}/>
      </div>
      {openNotify ? (
        <div className="p-3 bigRadius bg-white position-absolute notificationDropdown">
          <div className="d-flex align-items-center justify-content-between  border-bottom pb-2 mb-2">
            <h6 className="fw-bold">Notifications</h6>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={handleNotification}
            ></button>
          </div>
          <NotificationModal />
        </div>
      ) : (
        ""
      )}


      <b style={{ color: 'black', marginRight: '5px', marginTop: '10px' }}>{LoginName}</b>
      {/* <b style={{ color: 'black', marginRight: '5px', marginTop: '10px' }}>Mana Admin</b> */}
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <CAvatar src={AdminUser} size="md" />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownHeader className="bg-white text-dark border-bottom fw-semibold py-2">
            Account
          </CDropdownHeader>
          <CDropdownItem onClick={handleShow} style={{ cursor: 'default' }}>
            <LockResetIcon className="me-2" />
            Change Password
          </CDropdownItem>

          <CDropdownItem onClick={handleConfirmOpen} style={{ cursor: 'default' }}>
            {/* <CIcon icon={cilLockLocked} className="me-2" /> Change Password */}
            <CIcon icon={cilLockLocked} className="me-2" /> Log Out
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{'color':'black'}}>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>No</Button>
          <Button onClick={logoutUser} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog> */}
      <CommonAlert
        open={open}
        message="Are you sure you want to logout?"
        handleClose={handleConfirmClose}
        handleEnable={logoutUser}
      />
      {/* Change Password */}
      <CommonModal show={changePd} handleClose={handleClose} title="Change Password">
        <Modal.Body className="p-4">
          <Formik
            enableReinitialize
            initialValues={{
              oldpassword: '',
              password: '',
              cnfpwd: '',
            }}
            validationSchema={Yup.object({
              oldpassword: Yup.string().required('Please enter old password'),
              password: Yup.string()
                .required('Please enter new password')
                .min(8, 'Password must be at least 8 characters')
                .matches(
                  /[A-Z]/,
                  'Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character',
                )
                .matches(
                  /[a-z]/,
                  'Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character',
                )
                .matches(
                  /\d/,
                  'Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character',
                )
                .matches(
                  /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/,
                  'Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special character',
                ),
              cnfpwd: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Confirm Password must match with New Password')
                .required('Please enter confirm password'),
            })}
            onSubmit={(values) => {
              setSavePassword(false)
              var data = {
                currentPassword: values?.oldpassword,
                newPassword: values?.password,
                confirmPassword: values?.cnfpwd,
              }
              // console.log('chData = ', data)
              ChangePasswordApi(data)
                .then((res) => {
                  // if (res.data.status) {
                    toast.success('Password changed successfully')
                    setSavePassword(true)
                    handleClose()
                  // }
                })
                .catch((error) => {
                  setSavePassword(true)
                  console.log({ Message: 'Api Not Working', Error: error })
                })
            }}
          >
            {(props) => {
              return (
                <Form encType="multipart/form-data">
                  <div className="content-main ">
                    <div className="form-group">
                      <div className="space position-relative mb-4">
                        <label className="text-dark">Old Password</label>
                        <br />
                        {/* <input type="password" name="oldpassword" placeholder="Enter Old Password" className="mt-2 bg-light form-control"/>
                        {props.touched.oldpassword && props.errors.oldpassword && (
                          <div className="error" style={{ color: "red" }}>
                            {props.errors.oldpassword}
                          </div>
                        )} */}
                        <MyTextInput
                          type="password"
                          name="oldpassword"
                          placeholder="Enter Old Password"
                          className="mt-2 bg-light form-control pe-5"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="space position-relative mb-4">
                        <label className="text-dark">New Password</label>
                        <br />
                        <div className="position-relative">
                          {/* <input
                            type={visible ? "text" : "password"}
                            name="password"
                            placeholder="Enter New Password"
                            className="mt-2 bg-light form-control"
                          /> */}
                          <MyTextInput
                            type={visible ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter New Password"
                            className="mt-2 bg-light form-control pe-5"
                          />
                          {visible ? (
                            <span className="eye position-absolute">
                              <img src="/images/eye-open.svg" onClick={handleVisible} />
                            </span>
                          ) : (
                            <span className="eye position-absolute">
                              <img src="/images/eye-close.svg" onClick={handleVisible} />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="space position-relative mb-4">
                        <label className="text-dark">Confirm Password</label>
                        <br />
                        <div className="position-relative">
                          <MyTextInput
                            type={visibleCfmPwd ? 'text' : 'password'}
                            name="cnfpwd"
                            placeholder="Enter Confirm Password"
                            className="mt-2 bg-light form-control pe-5"
                          />
                          {visibleCfmPwd ? (
                            <span className="eye position-absolute">
                              <img src="/images/eye-open.svg" onClick={handleVisibleCfmPWD} />
                            </span>
                          ) : (
                            <span className="eye position-absolute">
                              <img src="/images/eye-close.svg" onClick={handleVisibleCfmPWD} />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Footer section */}
                    <div className="space text-end">
                      <br />
                      <Button
                        type="submit"
                        className="btn btn-primary rounded-pill py-3 w-100"
                        disabled={!savePassword}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </CommonModal>
    </>
  )
}

export default AppHeaderDropdown
