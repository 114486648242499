import React from 'react'
import './Loader.css'
import Lodr from '../../assets/images/Loader/LOADER.gif'
import { Blocks } from  'react-loader-spinner'

function Loader() {
  return (
    <>
        <div className='loader'>
            {/* <img src={Lodr} alt='Image'/> */}
            <Blocks
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            />
        </div>
    </>
  )
}

export default Loader
