import CryptoJS from 'crypto-js'
import { csvFileName } from 'src/helper';

export const setLocalStorageToken = (key,data) =>{
    localStorage.setItem(key,CryptoJS.AES.encrypt(data, key).toString())
}

export const getLocalStorageToken = (key) =>{
    let local = localStorage.getItem(key);
    if(local){
        let bytes  = CryptoJS.AES.decrypt(local,key);
        let originalText = bytes.toString(CryptoJS.enc.Utf8)
        return originalText
    }  else{
        return false
    }
}

export const encryptId = (key,data) =>{
    return key,CryptoJS.AES.encrypt(data, key).toString()
}
export const decryptId = (key,data) =>{
    let bytes  = CryptoJS.AES.decrypt(data,key);
    let originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
}

export const checkValidData = (data) => {
    return data || "N/A"
}

export const getCsvName = (path,csvFileName) => {
    let data = path.replace("/", "");
    return csvFileName || "test.csv"
    // return csvFileName?.[data] || "test.csv"
}
